

.container-color {
  background-color: white;
}

body {
  /* Location of the image. This image is licensed by creative commons */
  background-image: url(./images/background-image.jpeg);
  
  background-position: center center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
  
  /*color that will be displayed
  while the background image is loading */
  background-color: #464646;

}

td {
  font-size: 14px;
}

.panel-grey {
  background-color: #ece6ff;
  border-left: 2px solid purple;
  /* max-width: 446px; */
  /* display:flex; */
  height:100%;
  padding-left:5px;
  /* padding-right: -30px; */ 
}

/* .tableFixHead { overflow: scroll} */
/* .tableFixHead thead th { position: sticky; top: 0; z-index: 1; background-color: white; } */
/* table  { border-collapse: collapse; width: 100%; } */
/* th, td { padding: 8px 16px; } */
/* th     { background:#eee; } */

@media screen and (min-width: 768px) {

  .panel-grey {
    border-top-right-radius: .4rem;
    border-bottom-right-radius: .4rem;
  }
}


.PFT {
  font-size: 10px;
}

/* Alternate */
.purchased {
  background-color: #256F5C;
  color: white;
}

.fulfill {
  background-color: #2B4970;
  color: white;
}

.transfer {
  background-color: #AA7F39;
  color: white;
}


.doc-description {
  text-align: center;
  font-family: 'Playfair Display SC', serif;
  border-bottom: 1px solid purple;
}

.order {
  color: purple;
  font-weight: bold;
}

form {
  margin-top: 1em;
}
.page-title {
  font-family: 'Inter', sans-serif;
  font-weight: 600;
}

.uploaded-file {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.extra-file-delete {
  margin-left: 2em;
}

.jumbotron {
  background-color: #ece6ff;
}
